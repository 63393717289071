@keyframes title-in {
  0% {
    opacity: 0;
    transform: translateY(8.125rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes effects-system-up {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}

@keyframes textIn {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: 0%;
  }
}

@keyframes image-loaded {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes image-up {
  0% {
    transform: translateY(110%);
  }
  100% {
    transform: translateY(0%);
  }
}
